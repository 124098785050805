@import "./fonts.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Lexend  -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
p,
ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 17px 1rem;
  border: none;
  box-shadow: none;
  outline: none;
}
