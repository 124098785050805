 
  @font-face {
    font-family: 'DrukWide';
    src: url('/fonts/DrukWide-Medium-Web.woff') format('woff'),
         url('/fonts/DrukWide-Medium-Web.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-Black.ttf') format('truetype');
    font-style: bold;
    font-weight: 900;
  }
  
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-ExtraBold.ttf') format('truetype');
    font-style: bold;
    font-weight: 800;
  }
  
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-ExtraLight.ttf') format('truetype');
    font-style: light;
    font-weight: 200;
  }
  
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-Light.ttf') format('truetype');
    font-style: light;
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'Lexend';
    src: url('/fonts/Lexend-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
  }
  
  